/*
 * Page: Lock Screen
 * -----------------
 */
/* ADD THIS CLASS TO THE <BODY> TAG */
.lockscreen {
  background: @gray;
}
.lockscreen-logo {
  font-size: 35px;
  text-align: center;
  margin-bottom: 25px;
  font-weight: 300;
  a {
    color: #444;
  }
}
.lockscreen-wrapper {
  max-width: 400px;
  margin: 0 auto;
  margin-top: 10%;
}
/* User name [optional] */
.lockscreen .lockscreen-name {
  text-align: center;
  font-weight: 600;
}
/* Will contain the image and the sign in form */
.lockscreen-item {
  .border-radius(4px);
  padding: 0;
  background: #fff;
  position: relative;
  margin: 10px auto 30px auto;
  width: 290px;
}
/* User image */
.lockscreen-image {
  .border-radius(50%);
  position: absolute;
  left: -10px;
  top: -25px;
  background: #fff;
  padding: 5px;
  z-index: 10;
  > img {
    .border-radius(50%);
    width: 70px;
    height: 70px;
  }
}

/* Contains the password input and the login button */
.lockscreen-credentials {
  margin-left: 70px;
  .form-control {
    border: 0;
  }
  .btn {
    background-color: #fff;
    border: 0;
    padding: 0 10px;
  }
}

.lockscreen-footer {
  margin-top: 10px;
}
