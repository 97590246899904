/*
 * Skin: Blue
 * ----------
 */
@import "../../bootstrap/mixins.less";
@import "../../bootstrap/variables.less";
@import "../variables.less";
@import "../mixins.less";

.skin-blue-light {
  //Navbar
  .main-header {
    .navbar {
      .navbar-variant(@light-blue; #fff);
      .sidebar-toggle {
        color: #fff;
        &:hover {
          background-color: darken(@light-blue, 5%);
        }
      }
      @media (max-width: @screen-header-collapse) {
        .dropdown-menu {
          li {
            &.divider {
              background-color: rgba(255, 255, 255, 0.1);
            }
            a {
              color: #fff;
              &:hover {
                background: darken(@light-blue, 5%);
              }
            }
          }
        }
      }
    }
    //Logo
    .logo {
      .logo-variant(@light-blue);
    }

    li.user-header {
      background-color: @light-blue;
    }
  }

  //Content Header
  .content-header {
    background: transparent;
  }

  //Create the sidebar skin
  .skin-light-sidebar(@light-blue);
  .main-footer {
    border-top-color: @gray;
  }
}

.skin-blue.layout-top-nav .main-header > .logo {
  .logo-variant(@light-blue);
}
